import styles from "./APropos.module.scss";

function APropos (){

    return ( 
        <div id="about-me" className={`margeResponsive ${styles.container}`}>
            <h2>A propos de moi</h2>
            <p className={styles.enSVRplus}>En savoir plus</p>

            <div>
                <div className={styles.firstInfo}>
                    <div>
                        {/* <p className={styles.nomAuteur}>Je m'appelle <span>Camara Aboubacar</span></p> */}
                        {/* <p className={styles.presentation}>Diplômé d'une lience (Bac+3) en développement web et mobile, pendant mon parcours et expériences en entreprise comme en indépendant, j’ai eu l’occasion de travailler sur divers projets, allant de la conception/intégration d'API Rest/GraphQL, d'applications web et mobile élégants à la mise en place de fonctionnalités ou microservices avancées. Je suis quelqu’un de curieux, motivé et toujours prêt à relever de nouveaux défis. Je suis passionné par le web et convaincu que c'est un domaine en constante évolution et j’aspire à contribuer à son développement.</p> */}
                        <p className={styles.presentation}>A travers mes expériences en entreprise comme en indépendant, j’ai eu l’occasion de travailler sur divers projets, allant de la conception/intégration d'API Rest/GraphQL, d'applications web et mobile à la mise en place de fonctionnalités ou microservices avancées. Je suis quelqu’un de curieux, motivé et toujours prêt à relever de nouveaux défis. <br /> <br />Je suis passionné par le web et convaincu que c'est un domaine en constante évolution et j’aspire à contribuer à son développement.</p>
                    </div>
                    
                    {/* <div className={styles.experiences}>
                        <p>4</p>
                        <p>Ans d'<span>Expériences</span></p>
                    </div> */}
                </div>

                <div className={styles.infoEcoordonnes}>
                    <div>
                        <p>Nom:</p>
                        <p>Camara Aboubacar</p>
                    </div>
                    
                    {/* <div>
                        <p>Date de naissance:</p>
                        <p>10, Janvier 2000</p>
                    </div> */}

                    <div>
                        <p>Email(@gmail.com):</p>
                        <p>aboubacarcamara0110</p>
                    </div>

                    <div>
                        <p>Localisation:</p>
                        <p>Bordeaux, France</p>
                    </div>

                    <div>
                        <p>TMJ</p>
                        <p>375€/jour</p>
                    </div>
                </div>
            </div>
        </div>
    )
}


export default APropos;