import styles from "./Accueil.module.scss";
import Profil from "../assets/images/profil.jpg"
import APropos from "../components/APropos/APropos";
// import MonParcours from "../components/MonParcours/MonParcours"
import MesCompetences from "../components/MesCompetences/MesCompetences";
import MonPortfolio from "../components/MonPortfolio/MonPortfolio";
import SmoothScrollLink from "../components/SmoothScrollLink/SmoothScrollLink";

function Accueil (){ 

    // const TxtType = function(el, toRotate, period) {
    //     this.toRotate = toRotate;
    //     this.el = el;
    //     this.loopNum = 0;
    //     this.period = parseInt(period, 10) || 2000;
    //     this.txt = '';
    //     this.tick();
    //     this.isDeleting = false;
    // };

    // TxtType.prototype.tick = function() {
    //     let i = this.loopNum % this.toRotate.length;
    //     let fullTxt = this.toRotate[i];

    //     if (this.isDeleting) {
    //     this.txt = fullTxt.substring(0, this.txt.length - 1);
    //     } else {
    //     this.txt = fullTxt.substring(0, this.txt.length + 1);
    //     }

    //     this.el.innerHTML = '<span class="wrap">'+this.txt+'</span>';

    //     let that = this;
    //     let delta = 200 - Math.random() * 100;

    //     if (this.isDeleting) { delta /= 2; }

    //     if (!this.isDeleting && this.txt === fullTxt) {
    //     delta = this.period;
    //     this.isDeleting = true;
    //     } else if (this.isDeleting && this.txt === '') {
    //     this.isDeleting = false;
    //     this.loopNum++;
    //     delta = 500;
    //     }

    //     setTimeout(function() {
    //     that.tick();
    //     }, delta);
    // };

    // window.onload = function() {
    //     let elements = document.getElementsByClassName('typewrite');
    //     for (let i=0; i<elements.length; i++) {
    //         let toRotate = elements[i].getAttribute('data-type');
    //         let period = elements[i].getAttribute('data-period');
    //         if (toRotate) {
    //           new TxtType(elements[i], JSON.parse(toRotate), period);
    //         }
    //     }

    //     let css = document.createElement("style");
    //     css.type = "text/css";
    //     css.innerHTML = ".typewrite > .wrap { border-right: 0.08em solid #000; padding-right: 10px; font-weight: bold; margin: 15px 0px; font-size: 50px}";
    //     document.body.appendChild(css);
    // };

    return (
        <>
            <div className={`margeResponsive ${styles.container}`}>
                <div className={styles.presentation}>
                    <div className={styles.imgProfil}><img src={Profil} alt="Phot de profil"/></div>
                    <div className={styles.prez}>
                        <div className={styles.infoDBase}>
                            <p>Hello, je suis freelance</p>
                            <div className={styles.typewriter}>
                                <p style={{fontWeight: "bold", margin: "15px 0px", fontSize: "45px"}}>Développeur Fullstack</p>
                                {/* <p className="typewrite" data-period="2000" data-type='[ "Développeur", "Fullstack", "Frontend", "Backend Nodejs" ]'>
                                    <span class="wrap"></span>
                                </p> */}
                            </div>
                            <p>résidant sur Bordeaux, France</p>
                        </div>

                        <div className={styles.buttonCTA}>
                            {/* <SmoothScrollLink targetId="#portfolio"><div className={styles.portfolio}>Voir mon portfolio</div></SmoothScrollLink> */}
                            <SmoothScrollLink targetId="#portfolio"><div className={styles.portfolio}>Mes réalisations</div></SmoothScrollLink>
                            <SmoothScrollLink targetId="#skills"><div className={styles.skills}>Mes compétences <i className="fa-regular fa-circle-down"></i></div></SmoothScrollLink>
                        </div>
                    </div>
                </div>

                <div className={styles.arrowAnnimation}>
                    <i className="fa-solid fa-arrow-down"></i>
                </div>
            </div>

            <APropos/>
            {/* <MonParcours/> */}
            <MesCompetences/>
            <MonPortfolio/>
        </>
    )
}


export default Accueil;