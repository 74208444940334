import styles from "./Footer.module.scss";
import { Link } from "react-router-dom";

function Footer (){

    return (
        <>
            <div className={`margeResponsive ${styles.container}`}>
                {/* <h2>Au plaisir de vous rencontrer</h2> */}
                <div className={styles.footer}>
                    <div className={styles.coordonnes}>
                        <h3>Ma localisation</h3>
                        <p>Bordeaux, France</p>
                        <p style={{fontStyle: "italic"}}>(je suis ouvert au déplacement, j'ai un Permis B)</p>

                        <h3>Mon téléphone</h3>
                        <p><Link to="tel:+33745649205">(033) 745 649 205</Link></p>

                    </div>
                </div>
                <div className={styles.IconeReseaux}>
                    <p><Link target="_blank" to="https://wa.me/33752466355"><i className="fa-brands fa-whatsapp"></i></Link></p>
                    {/* <p><Link target="_blank" to="https://github.com/kmaraab"><i className="fa-brands fa-github"></i></Link></p> */}
                    <p><Link target="_blank" to="https://www.linkedin.com/in/aboubacar-camara-6a4301196/"><i className="fa-brands fa-linkedin-in"></i></Link></p>
                    <p><Link to="mailto:aboubacarcamara0110@gmail.com"><i className="fa-regular fa-envelope"></i></Link></p>
                </div>
            </div>

            <div className={styles.bottomFooter}>
                Copyright © 2024 choosews. Tous droits réservés.
            </div>
        </>
    )
}


export default Footer;